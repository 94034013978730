import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { getResourcePreviewData } from '../utils/preview.utils';
import ContentPageTemplate from './contentPage.template';

const PreviewTemplate = () => {
  const [slug, setSlug] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageContext, setPageContext] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (async () => {
        const query = queryString.parse(window.location.search);
        setSlug(query.id);
        const pgCnxt = await getResourcePreviewData(query.id);
        setPageContext(pgCnxt);
        setLoading(false);
      })();
    }
  }, [])
  
  return (
    <>
      {loading && <h1>LOADING....</h1>}
      {pageContext  && (
        <ContentPageTemplate pageContext={pageContext} />
      )}
    </>
  )
};

PreviewTemplate.propTypes = {
  pageContext: PropTypes.any,
};

PreviewTemplate.defaultProps = {
  pageContext: {},
};

export default PreviewTemplate;