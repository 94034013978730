/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
const cuid = require("cuid");

const unescapeHTML = (escapedHTML) => {
  if (typeof escapedHTML === "string") {
    return escapedHTML
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&nbsp;/g, " ")
      .replace(/%3A/g, ":")
      .replace(/%20/g, " ")
      .replace(/&rsquo;/g, "'");
  }
  return "";
};

const searchString = (string, pattern) =>
  string
    .match(new RegExp(pattern.source, pattern.flags))
    .map((match) => new RegExp(pattern.source, pattern.flags).exec(match))[0];

// Add later perhaps
// const convertArticleList = (data, _mutatedContent, _arrayOfComponents) => {
//   let mutatedContent = _mutatedContent;
//   const arrayOfComponents = _arrayOfComponents;
//   const InlineImage = data.match(/(\[inline-article-list src\s?=\s?["“]+(.[^"“]+)["“][^\]]*\])/g);
//   if (InlineImage !== null && InlineImage.length > 0) {
//     InlineImage.forEach((inline) => {
//       const parsedItems = searchString(inline, /src\s?=\s?["“]+(.[^"“]+)["“]/g)
//       if (parsedItems !== null && parsedItems.length > 0) {
//         const inlineIndex = data.indexOf(inline);
//         const inlineLength = inline.length;

//         const articles = parsedItems[1]
//         arrayOfComponents.push({
//           type: 'jsx-inline-article-list',
//           index: inlineIndex,
//           length: inlineLength,
//           content: {
//             src: parsedItems[1],
//           },
//         });
//         mutatedContent = mutatedContent.replace(inline, `{${inlineIndex}}`);
//       }
//     });
//   }
//   return [arrayOfComponents, mutatedContent];
// }

//
// INLINE COMPONENTS
//
const convertNewsletterInline = (data, _mutatedContent, _arrayOfComponents) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const Newsletter = data.match(/(\[newsletter\^[^\]]*\])/g);
  if (Newsletter !== null && Newsletter.length > 0) {
    Newsletter.forEach((newsletter) => {
      const inlineIndex = data.indexOf(newsletter);
      const inlineLength = newsletter.length;
      arrayOfComponents.push({
        type: "jsx-newsletter",
        index: inlineIndex,
        length: inlineLength,
        content: {},
      });
      mutatedContent = mutatedContent.replace(newsletter, `{${inlineIndex}}`);
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertInlineSocial = (data, _mutatedContent, _arrayOfComponents) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const MainMatch = data.match(
    /(\[inline-social(?:\stext\s?=\s?["“]+(.[^"“]+)["“])?[^\]]*\])/g
  );
  if (MainMatch !== null && MainMatch.length > 0) {
    MainMatch.forEach((item) => {
      let parsedItems = null;
      if (item.match(/text\s?=\s?["“]+(.[^"“]+)["“]/g)) {
        parsedItems = searchString(item, /(?:text\s?=\s?["“]+(.[^"“]+)["“])/g);
      }
      const inlineIndex = data.indexOf(item);
      const inlineLength = item.length;
      arrayOfComponents.push({
        type: "jsx-inline-social",
        index: inlineIndex,
        length: inlineLength,
        content: {
          text: parsedItems && parsedItems.length > 0 ? parsedItems[1] : null,
        },
      });
      mutatedContent = mutatedContent.replace(item, `{${inlineIndex}}`);
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertInlineQuestionForm = (
  data,
  _mutatedContent,
  _arrayOfComponents
) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const MainMatch = data.match(/(\[inline-question-form\^[^\]]*\])/g);
  if (MainMatch !== null && MainMatch.length > 0) {
    MainMatch.forEach((item) => {
      const inlineIndex = data.indexOf(item);
      const inlineLength = item.length;
      arrayOfComponents.push({
        type: "jsx-inline-question-form",
        index: inlineIndex,
        length: inlineLength,
        content: {},
      });
      mutatedContent = mutatedContent.replace(item, `{${inlineIndex}}`);
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertInlineImages = (data, _mutatedContent, _arrayOfComponents) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const InlineImage = data.match(
    /(\[inline-image src\s?=\s?["“]+(.[^"“]+)["“]+(?:\salt\s?=\s?["“]+(.[^"“]+)["“])?(?:\swidth\s?=\s?["“]+(.[^"“]+)["“])?[^\]]*\])/g
  );
  if (InlineImage !== null && InlineImage.length > 0) {
    InlineImage.forEach((inline) => {
      const parsedSource = searchString(
        inline,
        /src\s?=\s?["“]+(.[^"“]+)["“]/g
      );
      let parsedAlt = null;
      let parsedWidth = null;
      if (inline.match(/alt\s?=\s?["“]+(.[^"“]+)["“]/g)) {
        parsedAlt = searchString(inline, /alt\s?=\s?["“]+(.[^"“]+)["“]/g);
      }
      if (inline.match(/width\s?=\s?["“]+(.[^"“]+)["“]/g)) {
        parsedWidth = searchString(inline, /width\s?=\s?["“]+(.[^"“]+)["“]/g);
      }
      if (parsedSource !== null && parsedSource.length > 0) {
        const inlineIndex = data.indexOf(inline);
        const inlineLength = inline.length;
        arrayOfComponents.push({
          type: "jsx-inline-image",
          index: inlineIndex,
          length: inlineLength,
          content: {
            src: parsedSource[1],
            alt: parsedAlt && parsedAlt[1] ? parsedAlt[1] : null,
            width: parsedWidth && parsedWidth[1] ? parsedWidth[1] : null,
          },
        });
        mutatedContent = mutatedContent.replace(inline, `{${inlineIndex}}`);
      }
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertLinkedHeader = (data, _mutatedContent, _arrayOfComponents) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const InlineImage = data.match(
    /(\[linked-header title\s?=\s?["“]+(.[^"“]+)["“]+(?:\sid\s?=\s?["“]+(.[^"“]+)["“])?[^\]]*\])/g
  );
  if (InlineImage !== null && InlineImage.length > 0) {
    InlineImage.forEach((inline) => {
      const parsedItems = searchString(
        inline,
        /title\s?=\s?["“]+(.[^"“]+)["“]+(?:\sid\s?=\s?["“]+(.[^"“]+)["“])?/g
      );
      if (parsedItems !== null && parsedItems.length > 0) {
        const inlineIndex = data.indexOf(inline);
        const inlineLength = inline.length;
        arrayOfComponents.push({
          type: "jsx-linked-header",
          index: inlineIndex,
          length: inlineLength,
          content: {
            title: parsedItems[1],
            id: parsedItems[2] || cuid(),
          },
        });
        mutatedContent = mutatedContent.replace(inline, `{${inlineIndex}}`);
      }
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertInlineImageList = (data, _mutatedContent, _arrayOfComponents) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const InlineImage = data.match(
    /(\[inline-image-list src\s?=\s?["“]+(.[^"“]+)["“]+(?:\salt\s?=\s?["“]+(.[^"“]+)["“])?[^\]]*\])/g
  );
  if (InlineImage !== null && InlineImage.length > 0) {
    InlineImage.forEach((inline) => {
      const parsedItems = searchString(
        inline,
        /src\s?=\s?["“]+(.[^"“]+)["“]+(?:\salt\s?=\s?["“]+(.[^"“]+)["“])?/g
      );
      if (parsedItems !== null && parsedItems.length > 0) {
        const inlineIndex = data.indexOf(inline);
        const inlineLength = inline.length;
        arrayOfComponents.push({
          type: "jsx-inline-image-list",
          index: inlineIndex,
          length: inlineLength,
          content: {
            src: parsedItems[1].split(","),
            alt: parsedItems[2] ? parsedItems[2].split(",") : null,
          },
        });
        mutatedContent = mutatedContent.replace(inline, `{${inlineIndex}}`);
      }
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertDropdowns = (
  data,
  _mutatedContent,
  _arrayOfComponents,
  inlines
) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const Dropdown = data.match(
    /(\[dropdown id\s?=\s?["“]+(.[^"“]+)["“][^\]]*\])/g
  );
  if (Dropdown !== null && Dropdown.length > 0) {
    Dropdown.forEach((dropdown) => {
      const parsedItems = searchString(
        dropdown,
        /id\s?=\s?["“]+(.[^"“]+)["“]/g
      );
      if (parsedItems !== null && parsedItems.length > 0) {
        const inlineIndex = data.indexOf(dropdown);
        const inlineLength = dropdown.length;
        const inline = inlines.filter(
          (item) => item.id === parsedItems[1] || item._id === parsedItems[1]
        );
        arrayOfComponents.push({
          type: "jsx-dropdown",
          index: inlineIndex,
          length: inlineLength,
          content: {
            title: inline.length > 0 ? inline[0].title : "",
            content: inline.length > 0 ? inline[0].content : "",
          },
        });
        mutatedContent = mutatedContent.replace(dropdown, `{${inlineIndex}}`);
      }
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertRelatedArticles = (
  data,
  _mutatedContent,
  _arrayOfComponents,
  articles
) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const ContextMatch = data.match(
    /(\[related-articles id\s?=\s?["“]+(.[^"“]+)["“][^\]]*\])/g
  );
  if (ContextMatch !== null && ContextMatch.length > 0) {
    ContextMatch.forEach((context) => {
      const parsedItems = searchString(context, /id\s?=\s?["“]+(.[^"“]+)["“]/g);
      if (parsedItems !== null && parsedItems.length > 0) {
        const inlineIndex = data.indexOf(context);
        const inlineLength = context.length;
        const listOfIds = parsedItems[1].split(",");

        const matchedArticles = articles.filter(
          (item) => listOfIds.includes(item.id) || listOfIds.includes(item._id)
        );
        arrayOfComponents.push({
          type: "jsx-related-articles",
          index: inlineIndex,
          length: inlineLength,
          content: {
            articles: matchedArticles,
          },
        });
        mutatedContent = mutatedContent.replace(context, `{${inlineIndex}}`);
      }
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertHopeThatHelped = (data, _mutatedContent, _arrayOfComponents) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const MainMatch = data.match(/(\[inline-hope-that-helped\^[^\]]*\])/g);
  if (MainMatch !== null && MainMatch.length > 0) {
    MainMatch.forEach((item) => {
      const inlineIndex = data.indexOf(item);
      const inlineLength = item.length;
      arrayOfComponents.push({
        type: "jsx-hope-that-helped",
        index: inlineIndex,
        length: inlineLength,
        content: {},
      });
      mutatedContent = mutatedContent.replace(item, `{${inlineIndex}}`);
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const convertCategoryArticleList = (
  data,
  _mutatedContent,
  _arrayOfComponents,
  categories,
  articles
) => {
  let mutatedContent = _mutatedContent;
  const arrayOfComponents = _arrayOfComponents;
  const MainMatch = data.match(/(\[inline-category-articles\^[^\]]*\])/g);
  if (MainMatch !== null && MainMatch.length > 0) {
    MainMatch.forEach((item) => {
      const inlineIndex = data.indexOf(item);
      const inlineLength = item.length;

      const filteredArticles = articles.map((article) => {
        const articleCategories = article.metadata.categories;
        let hasCategory = false;
        articleCategories.forEach((category) => {
          categories.forEach((cat) => {
            if (cat.slug === category.slug) {
              hasCategory = true;
            }
          });
        });
        if (hasCategory) {
          return article;
        }
      });
      arrayOfComponents.push({
        type: "jsx-inline-category-articles",
        index: inlineIndex,
        length: inlineLength,
        content: {
          articles: filteredArticles,
          categories,
        },
      });
      mutatedContent = mutatedContent.replace(item, `{${inlineIndex}}`);
    });
  }
  return [arrayOfComponents, mutatedContent];
};

const uniqueInlines = (content) => {
  const newData = content.replace(
    /\[(.[^\]]+)]/g,
    (entry, match) => `[${match}^${cuid.slug()}^]`
  );

  return newData;
};

const replaceAllNumbas = (content) => {
  const newContent = content.replace(/\[([^\^]+)\^[^\^]+\^\]/g, "[$1]");

  return newContent;
};

const combineCTAS = (data, _mutatedContent, _arrayOfComponents) => {
  const arrayOfComponents = _arrayOfComponents;
  let mutatedContent = _mutatedContent;
  const finalComponentArray = [];
  // Sorts the array of components based off their index i.e {1327}, {1388}
  arrayOfComponents.sort((a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    return -1;
  });

  let highestIndex = 0;
  arrayOfComponents.forEach((component) => {
    if (mutatedContent === undefined) {
      return;
    }
    const contentArray = mutatedContent.split(`{${component.index}}`);
    finalComponentArray.push({
      type: "html",
      content: contentArray[0],
      index: component.index - 0.1,
    });
    highestIndex = component.index;
    finalComponentArray.push(component);
    if (contentArray.length > 1) {
      [, mutatedContent] = contentArray;
    } else {
      [mutatedContent] = contentArray;
    }
  });
  finalComponentArray.push({
    type: "html",
    content: mutatedContent,
    index: highestIndex + 1,
  });
  finalComponentArray.sort((a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    return -1;
  });
  if (finalComponentArray.length === 0) {
    return [
      {
        type: "html",
        data,
        index: 0,
      },
    ];
  }
  return finalComponentArray;
};

module.exports = parsePostContentForInlineCTAs = (
  rawContent,
  inlines,
  articles,
  categories
) => {
  let arrayOfComponents = [];
  const content = unescapeHTML(rawContent);
  const unravelledContent = uniqueInlines(content);
  let mutatedContent = unravelledContent;

  [arrayOfComponents, mutatedContent] = convertDropdowns(
    unravelledContent,
    mutatedContent,
    arrayOfComponents,
    inlines
  );
  [arrayOfComponents, mutatedContent] = convertInlineImages(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertInlineImageList(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertNewsletterInline(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertInlineSocial(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertInlineQuestionForm(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertLinkedHeader(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertHopeThatHelped(
    unravelledContent,
    mutatedContent,
    arrayOfComponents
  );
  [arrayOfComponents, mutatedContent] = convertRelatedArticles(
    unravelledContent,
    mutatedContent,
    arrayOfComponents,
    articles
  );
  [arrayOfComponents, mutatedContent] = convertCategoryArticleList(
    unravelledContent,
    mutatedContent,
    arrayOfComponents,
    categories,
    articles
  );

  const ravioliContent = replaceAllNumbas(mutatedContent);
  return combineCTAS(ravioliContent, ravioliContent, arrayOfComponents);
};
