import Cosmic from 'cosmicjs';
import parsePostContentForInlineCTAs from './inline.utils';

const api = Cosmic();
const bucket = api.bucket({
  slug: "bidddy-blog-production",
  read_key: process.env.GATSBY_COSMIC_READ_KEY,
});

const getPageUrl = (page) => {
  let finalURL = '';
  const lastPartURL = page.metadata.url_path;
  const parentPath = page.metadata.parent_page && page.metadata.parent_page.metadata ? page.metadata.parent_page.metadata.url_path : '';
  if (parentPath !== '') {
    finalURL = `/${parentPath}/${lastPartURL}/`;
  } else {
    finalURL = `/${lastPartURL}/`;
  }

  return finalURL;
}

const getResourcePreviewData = async (slug) => {
  
  const { object } = (await bucket.getObject({
    slug: encodeURIComponent(slug),
    status: 'all',
    hide_metafields: true,
    props: '_id,title,content,modified_at,metadata',
  }))

  const { objects: inlines } = (await bucket.getObjects({
    type: 'inlines',
    status: 'all',
    hide_metafields: true,
    props: '_id,title,content,metadata',
  }))
  
  const { objects: contentPages } = (await bucket.getObjects({
    type: 'content-pages',
    status: 'all',
    hide_metafields: true,
    props: '_id,title,content,modified_at,metadata',
  }))
  
  const urlPath = getPageUrl(object);

  const metaContext = {
    title: object.metadata.meta.title,
    description: object.metadata.meta.description,
    image: object.metadata.image ? {
      imgix_url: object.metadata.image.imgix_url,
    } : null,
    canonical: `https://bidddy.com${urlPath}`,
    modified_at: object.modified_at
  }
  // need to parse content for inlines
  // const inlines = _.map(Inlines.data.allCosmicjsInlines.edges, 'node');
  const content = parsePostContentForInlineCTAs(object.content, inlines, contentPages, object.metadata.categories);

  const context = {
    pathname: urlPath,
    metaContext: metaContext,
    categories: object.metadata.categories,
    content: content,
    author: object.metadata.author,
    modified_at: object.modified_at,
    title: object.title
  }
  
  return context;
}

export {
  getResourcePreviewData,
}
