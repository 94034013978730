import React, { useEffect } from "react";

const useLocation = () => {
  const [location, setLocation] = React.useState(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setLocation(window.location);
    }
    const handleLocationChange = () => setLocation(window.location);
    window.addEventListener("popstate", handleLocationChange);
    return () => window.removeEventListener("popstate", handleLocationChange);
  }, []);
  return location;
};

export default useLocation;
