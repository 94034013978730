/* eslint-disable max-depth */
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import jwtDecode from "jwt-decode";
import queryString from "query-string";
import { sendToast } from "../../utils/helpers";
import { baseURL, isAuthenticated } from "../../utils/axios";
import useLocation from "./useLocation";

const useAuthHook = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();

  const checkLocationAuth = async () => {
    if (location) {
      const { token } = queryString.parse(location.search);
      if (token) {
        try {
          const response = await axios.post(`${baseURL}/refresh/`, {
            refresh_token: token,
          });
          const newAccess = response.data.token;
          const newRefresh = response.data.refresh_token;
          const accountType = response.data.type;
          const decodedAccess: { [key: string]: any } = jwtDecode(newAccess);
          const decodedRefresh: { [key: string]: any } = jwtDecode(newRefresh);
          Cookies.set("access", newAccess, {
            expires: new Date(decodedAccess.exp * 1000),
          });
          if (accountType) {
            Cookies.set("accountType", accountType, {
              expires: new Date(decodedAccess.exp * 1000),
            });
          }
          Cookies.set("refresh", newRefresh, {
            expires: new Date(decodedRefresh.exp * 1000),
          });
          return true;
        } catch (err) {
          sendToast("There was an error authenticating please try again", {
            type: "error",
          });
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAuthenticated() && location) {
        const isAuthed = await checkLocationAuth();
        setAuthenticated(isAuthed);
      }
      if (isAuthenticated()) {
        setAuthenticated(true);
      }
    })();
  }, [location]);

  return [authenticated, checkLocationAuth] as any;
};

export default useAuthHook;
