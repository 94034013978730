import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ExhibitResponse, getEvent } from "../../../store/events/actions";
import {
  isLoading,
  selectCurrentOption,
  selectEvent,
  selectEventErrors,
} from "../../../store/events/selectors";
import { useAppDispatch } from "../../../store";
import PublicLayout from "../../../components/PublicLayout";
import PublicAuctionList from "../../../components/Public/PublicAuctionList";
import FullScreenLoading from "../../../components/Loading/FullScreenLoading";
import PublicEventMain from "../../../components/Public/PublicEventMain";
import PublicOptionsSelector from "../../../components/Public/PublicOptionsSelector";
import {
  clearError,
  PublicOptions,
  resetState,
} from "../../../store/events/reducer";
import PublicHowToBid from "../../../components/Public/PublicHowToBid";
import PublicContactUs from "../../../components/Public/PublicContactUs";

const PublicEvent = ({ id }) => {
  const dispatch = useAppDispatch();
  const loading = useSelector((state) => isLoading(state));
  const event = useSelector((state) =>
    selectEvent(state)
  ) as ExhibitResponse | null;
  const selectedOption = useSelector((state) => selectCurrentOption(state));
  const eventError = useSelector((state) => selectEventErrors(state));

  useEffect(() => {
    if (eventError) {
      dispatch(clearError());
      navigate("/404/");
    }
  }, [eventError]);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      // clear local storage
      localStorage.clear();
    }
    dispatch(clearError());
    dispatch(resetState());
    if (id) {
      // fetch auction
      dispatch(getEvent(id));
    } else {
      navigate("/404/");
    }
  }, []);

  const getEventLink = () => {
    if (event && event.data && event.data.slug) {
      return `/p/events/${
        event && event.data && event.data.slug ? event.data.slug : ""
      }/`;
    }
    return "";
  };

  return (
    <PublicLayout
      whiteBackground
      logo={event && event.data ? event.data.logo_url : ""}
      title={event && event.data ? event.data.name : ""}
      username={event && event.data ? event.data.username : ""}
      link={getEventLink()}
    >
      <PublicOptionsSelector />
      {event && event.data && selectedOption === PublicOptions.AuctionItems && (
        <PublicEventMain />
      )}
      {event && event.data && selectedOption === PublicOptions.AuctionItems && (
        <PublicAuctionList />
      )}
      {event && event.data && selectedOption === PublicOptions.HowToBid && (
        <PublicHowToBid />
      )}
      {event && event.data && selectedOption === PublicOptions.ContactUs && (
        <PublicContactUs />
      )}
      {loading && <FullScreenLoading />}
    </PublicLayout>
  );
};

export default PublicEvent;
