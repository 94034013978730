import React from "react";
import Imgix from "react-imgix";
import cn from "classnames";
import { navigate } from "gatsby";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import DefaultButton from "../../Buttons/DefaultButton";
import styles from "../styles/Home.module.scss";

const Auctions = () => (
  <section className="w-full bg-white md:py-32 py-16">
    <div className={styles.powerTop}>
      <div className="md:pl-12 md:w-1/2 w-full flex-col flex justify-start h-full">
        <Imgix
          src="https://imgix.cosmicjs.com/149be9a0-9c12-11ed-93ee-cb9a2cd68754-PowerOfOne.png?auto=format"
          height={887}
          width={976}
          htmlAttributes={{
            alt: "Instagram Auctions",
            src: `https://imgix.cosmicjs.com/149be9a0-9c12-11ed-93ee-cb9a2cd68754-PowerOfOne.png?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className={cn(styles.powerImage, "lazyload blur-up")}
        />
        <h3 className="font-roboto text-bidddyPink text-lg mb-2 md:mt-16">
          AUCTIONS
        </h3>
        <h2 className="font-inter text-6xl font-bold text-bidddyAltGray mb-6">
          What is a
          <br /> social auction?
        </h2>
        <h4 className="font-cubano text-bidddyGray text-2xl leading-7 mb-3">
          Make a post.
          <br />
          Bid in the comments.
          <br />
          We&apos;ll handle the rest!
          <br />
        </h4>
        <p className="font-inter font-medium text-base leading-6 mb-8 max-w-md">
          Bidddy makes it easy for resellers to non-profits and everyone in
          between to easily run auctions on Instagram. Generate awareness, boost
          engagement and grow online all while saving time!
        </p>
        <DefaultButton
          extraClasses={styles.defaultButton}
          onClick={() => navigate("/signup/")}
          title="Try Bidddy For Free"
        />
      </div>
    </div>
  </section>
);

export default Auctions;
