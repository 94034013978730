import React, { createContext, useEffect, useState } from "react";
import { Socket } from "phoenix";

const PhoenixSocketContext = createContext({ socket: null });

const baseSocketUrl = process.env.GATSBY_PUBLIC_APP_SOCKET_URL;

const PhoenixSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = new Socket(baseSocketUrl);
    console.log("SOCKET");
    socket.connect();
    console.log("SOCKET CONNECTED");
    setSocket(socket);
  }, []);
// Add fallback UI
  if (!socket) {
    return null;
  }

  return (
    <PhoenixSocketContext.Provider value={{ socket }}>
      {children}
    </PhoenixSocketContext.Provider>
  );
};

export { PhoenixSocketContext, PhoenixSocketProvider };
