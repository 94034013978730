import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { useSelector } from "react-redux";
import { ExhibitResponse } from "../../store/events/actions";
import { selectEvent } from "../../store/events/selectors";
import styles from "./PublicMainAuction.module.scss";
import { sendToast } from "../../utils/helpers";

const ShareArrow = () => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0V5.22679C3.45134 5.53518 0 8.93822 0 15.2038V17.618L1.70711 15.9109C3.95393 13.6641 6.69021 12.7227 10 13.0756V18.4075L20.5186 9.20377L10 0ZM12 7.20377V4.40754L17.4814 9.20377L12 14V11.3566L11.1644 11.2174C7.74982 10.6483 4.74443 11.2183 2.20125 12.9203C2.94953 9.05479 5.79936 7.20377 11 7.20377H12Z"
      fill="#F4799E"
    />
  </svg>
);

const THRIVE_IMAGE =
  "https://imgix.cosmicjs.com/234ac590-e10d-11ec-8e11-7b73d7c60456-ThriveImage.png";

const PublicEventMain = () => {
  const event = useSelector((state) =>
    selectEvent(state)
  ) as ExhibitResponse | null;

  const handleShare = () => {
    const url = `https://bidddy.com/p/events/${event.data.slug}/`;
    if (navigator.canShare) {
      navigator.share({
        title: event.data.name,
        text: "Check out this event!",
        url,
      });
    } else {
      const copyText = url;
      // copy to clipboard
      navigator.clipboard
        .writeText(copyText)
        .then(() =>
          // console.log('Async: Copying to clipboard was successful!');
          sendToast("Copied Link to Clipboard!", { type: "success" })
        )
        .catch(() => {
          console.error("Error copying text");
        });
    }
  };

  return (
    <div className={styles.publicMainContainer}>
      <div className={styles.publicMainImageContainer}>
        <>
          <Imgix
            src={`${
              (event && event.data && event.data.profile_url) || THRIVE_IMAGE
            }`}
            height={480}
            width={519}
            htmlAttributes={{
              onError: ({ currentTarget }) => {
                currentTarget.onError = null;
                currentTarget.src = null;
              },
              alt: "Public Event Image",
              src: `${
                (event && event.data && event.data.profile_url) || THRIVE_IMAGE
              }`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
        </>
      </div>
      <div className={styles.publicMainContentContainer}>
        <h4 className={styles.user}>
          <a
            target="_blank"
            rel="noreferrer nofollow noopener"
            href={`http://instagram.com/${event.data.username}`}
          >
            @{event && event.data && event.data.username}
          </a>
        </h4>
        <h1 className={styles.eventTitle}>
          {(event && event.data && event.data.name) || "Made by Toronto"}
        </h1>
        <h2 className={styles.eventSubTitle}>Live on Instagram</h2>
        <h5 className={styles.eventDate}>
          {event && event.data && event.data.date_range}
        </h5>
        <p className={styles.auctionDescription}>
          {event && event.data && event.data.description}
        </p>
        <div className={styles.actionsContainer}>
          <div className={styles.bid} />
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => handleShare()}
            className={styles.share}
            onClick={() => handleShare()}
          >
            <ShareArrow />
            <p>TELL A FRIEND</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicEventMain;
