import React from "react";
import Imgix from "react-imgix";
import cn from "classnames";
import { navigate } from "gatsby";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import DefaultButton from "../../Buttons/DefaultButton";
import styles from "../styles/Home.module.scss";

const AuctionConsulting = () => (
  <section className="w-full bg-bidddyLinkOutGray md:pt-24 md:pb-32 py-8">
    <div className={styles.consulting}>
      <div className="md:w-1/3 w-full flex-col flex justify-center ml-0 md:mr-16">
        <Imgix
          src="https://imgix.cosmicjs.com/737c0980-9c32-11ed-93ee-cb9a2cd68754-ConsultingImage.png?auto=format"
          height={887}
          width={974}
          htmlAttributes={{
            alt: "Auction Consulting",
            src: `https://imgix.cosmicjs.com/737c0980-9c32-11ed-93ee-cb9a2cd68754-ConsultingImage.png?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className={cn(styles.powerImage, "lazyload blur-up")}
        />
        <h3 className="font-roboto text-bidddyPink text-lg mb-2 md:pt-32 uppercase">
          Auction Consulting
        </h3>
        <h2 className="font-inter md:text-6xl text-4xl font-bold text-bidddyAltGray mb-6">
          We&apos;re Here
          <br /> to Help
        </h2>
        <p className="font-inter font-medium text-base leading-6 mb-10 md:max-w-sm">
          While our auctions are automated, we are not. Our social auction
          experts are here on standby, ready to offer a wide variety of
          consulting, design, marketing, and strategic services. Contact us to
          learn more or just to say hello and tell us how it&apos;s going.
        </p>
        <DefaultButton
          title="Contact Us"
          onClick={() => navigate("/contact-us/")}
          extraClasses={styles.defaultButton}
        />
      </div>
    </div>
  </section>
);

export default AuctionConsulting;
