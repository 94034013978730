import React from "react";

const BidddyLogoSmall = () => (
  <svg
    style={{
      minHeight: "55px",
    }}
    width="37"
    height="55"
    viewBox="0 0 37 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90242 1.55247C8.49227 2.37209 7.23056 6.09762 4.55869 16.8272C-1.82409 42.6079 -0.636595 56.6904 7.8243 54.8276C9.67976 54.3806 9.90242 54.157 9.30867 52.9649C7.23056 49.0903 8.86336 36.9451 13.9102 18.6899C16.4336 9.37609 18.3633 6.61919 22.7422 5.65055C25.7852 4.97996 27.1211 6.24664 27.1211 10.1212C27.1211 13.8467 24.2266 18.3174 20.1446 20.8507C18.6602 21.8194 17.4727 22.788 17.4727 23.0116C17.4727 23.3096 19.0313 24.3527 20.961 25.3959C26.082 28.1528 27.4922 30.5371 27.418 36.0509C27.3437 42.9804 23.707 48.4942 19.1797 48.4942C15.543 48.4942 15.9883 44.5452 20.3672 38.9568L23.336 35.2313L21.4063 34.7097C16.2852 33.4431 11.5352 37.3176 11.5352 42.9059C11.5352 50.5805 20.5157 54.4551 28.6797 50.2825C35.9531 46.5569 38.7734 36.349 34.4687 29.345C32.6875 26.5136 28.2344 23.1606 26.2305 23.1606C24.0781 23.1606 24.7461 22.1919 28.457 19.7331C34.7656 15.5605 36.9179 8.33294 33.207 3.78779C31.2773 1.32893 26.0078 0.956379 21.7031 2.89366L18.2149 4.45839L18.4375 2.81915C18.586 1.32893 18.2149 1.17991 14.8751 1.03089C12.8712 0.88187 10.5704 1.17991 9.90242 1.55247Z"
      fill="#F4799E"
    />
  </svg>
);

export default BidddyLogoSmall;
