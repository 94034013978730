import React from "react";
import PricingPage from "../components/Pricing";
import Layout from "../components/Layout";

const Pricing = () => (
  <Layout
    hideFooter
    showHomeFAQ
    image="https://imgix.cosmicjs.com/1ea993f0-de97-11eb-a12b-17ba28648e41-HomeOgImage.png"
    description="Bidddy Pricing - Take your fundraising to the next level with Bidddy's social auction platform."
    title="Pricing | Bidddy"
    canonical="https://bidddy.com/pricing"
  >
    <PricingPage />
  </Layout>
);

export default Pricing;
