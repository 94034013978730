import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ExhibitResponse,
  getEvent,
  getPublicAuction,
  PublicAuctionResponse,
} from "../../../store/events/actions";
import {
  selectPublicAuction,
  isLoading,
  selectEvent,
  selectCurrentOption,
  selectEventErrors,
} from "../../../store/events/selectors";
import { useAppDispatch } from "../../../store";
import PublicLayout from "../../../components/PublicLayout";
import PublicMainAuction from "../../../components/Public/PublicMainAuction";
import FullScreenLoading from "../../../components/Loading/FullScreenLoading";
import PublicOptionsSelector from "../../../components/Public/PublicOptionsSelector";
import {
  clearError,
  PublicOptions,
  resetState,
  selectOption,
} from "../../../store/events/reducer";
import PublicAuctionList from "../../../components/Public/PublicAuctionList";
import PublicHowToBid from "../../../components/Public/PublicHowToBid";
import PublicContactUs from "../../../components/Public/PublicContactUs";
import { selectSuccessfullySubmittedBid } from "../../../store/bidding/selectors";

const PublicAuctions = ({ id }) => {
  const dispatch = useAppDispatch();
  const loading = useSelector((state) => isLoading(state));
  const publicAuction = useSelector((state) =>
    selectPublicAuction(state)
  ) as PublicAuctionResponse | null;
  const successfullySubmittedBid = useSelector((state) =>
    selectSuccessfullySubmittedBid(state)
  );
  const event = useSelector((state) =>
    selectEvent(state)
  ) as ExhibitResponse | null;
  const selectedOption = useSelector((state) => selectCurrentOption(state));
  const eventError = useSelector((state) => selectEventErrors(state));
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (eventError) {
      dispatch(clearError());
      navigate("/404/");
    }
  }, [eventError]);

  useEffect(() => {
    // Clear local storage
    if (typeof localStorage !== "undefined") {
      // clear local storage
      localStorage.clear();
    }
    dispatch(clearError());
    dispatch(resetState());
    if (id) {
      // fetch auction
      setFetching(true);
      dispatch(getPublicAuction(id));
      dispatch(selectOption(PublicOptions.AuctionItems));
    } else {
      navigate("/404/");
    }
  }, [id]);

  useEffect(() => {
    if (id && id !== "undefined" && successfullySubmittedBid) {
      // fetch auction
      dispatch(getPublicAuction(id));
      dispatch(selectOption(PublicOptions.AuctionItems));
    }
  }, [successfullySubmittedBid]);

  useEffect(() => {
    if (publicAuction && fetching) {
      setFetching(false);
      dispatch(getEvent(publicAuction.data.exhibit_id));
    }
  }, [publicAuction]);

  return (
    <PublicLayout
      whiteBackground
      logo={event && event.data ? event.data.logo_url : ""}
      title={event && event.data ? event.data.name : ""}
      username={event && event.data ? event.data.username : ""}
      link={
        event && event.data
          ? `/p/events/${event && event.data ? event.data.slug : ""}/`
          : ""
      }
    >
      <PublicOptionsSelector />
      {publicAuction &&
        event &&
        publicAuction &&
        publicAuction.data &&
        publicAuction.data.token &&
        selectedOption === PublicOptions.AuctionItems && <PublicMainAuction />}
      {publicAuction &&
        event &&
        publicAuction &&
        selectedOption === PublicOptions.AuctionItems && <PublicAuctionList />}
      {publicAuction &&
        event &&
        publicAuction &&
        selectedOption === PublicOptions.HowToBid && <PublicHowToBid />}
      {publicAuction &&
        event &&
        publicAuction &&
        selectedOption === PublicOptions.ContactUs && <PublicContactUs />}
      {fetching && <FullScreenLoading />}
    </PublicLayout>
  );
};

export default PublicAuctions;
