import { useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { BidderBidProps, submitBid } from "../../store/bidding/actions";
import { useAppDispatch } from "../../store";
import { selectPublicAuction } from "../../store/events/selectors";
import { PublicAuctionResponse } from "../../store/events/actions";
import useLocation from "./useLocation";

const useConfirmBidHook = ({ authenticated }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const publicAuction = useSelector((state) =>
    selectPublicAuction(state)
  ) as PublicAuctionResponse | null;
  useEffect(() => {
    if (authenticated && publicAuction) {
      const { bid } = queryString.parse(location.search);
      if (bid && publicAuction) {
        const props = {
          bid: { amount: Number(bid) },
          token: publicAuction.data.token,
        } as BidderBidProps;
        dispatch(submitBid(props));
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
  }, [authenticated, publicAuction]);

  return [];
};

export default useConfirmBidHook;
