import { PublicAuctionResponse } from "../../../store/events/actions";

/**
 * Checks for minimum bid increment requirements
 * @param bid (number)
 * @param publicAuction (PublicAuctionResponse)
 * @returns true/false (boolean)
 */
const checkMinBidRequirements = (bid, publicAuction: PublicAuctionResponse) => {
  if (
    bid >=
    publicAuction.data.auction.current_bid_price +
      publicAuction.data.auction.increment
  ) {
    return true;
  }
  return false;
};

export { checkMinBidRequirements };
