import { navigate } from "gatsby";
import React from "react";
import DefaultButton from "../../Buttons/DefaultButton";

const PricingCard = ({
  title,
  description,
  cost,
  costPer = "auction",
  onClick,
  offerings,
  buttonTitle,
}) => (
  <div
    className="flex flex-col gap-2 bg-white rounded-lg p-8"
    style={{ height: "fit-content" }}
  >
    <h2 className="font-cubano text-bidddyAltGray text-3xl">{title}</h2>
    <p className="font-inter text-base text-bidddyAltGray">{description}</p>
    <div className="flex flex-row items-start my-6">
      <p className="font-inter font-normal text-bidddyAltGray text-lg">CAD</p>
      <p className="font-inter font-bold text-bidddyAltGray text-lg">&nbsp;$</p>
      <p className="font-inter font-bold text-6xl text-bidddyAltGray">{cost}</p>
      <p className="font-inter font-bold text-xl text-bidddyPink self-end justify-end align-bottom">
        /{costPer}
      </p>
    </div>
    <DefaultButton title={buttonTitle} onClick={onClick} />
    <div className="my-5">{offerings}</div>
  </div>
);

const PricingPlans = () => {
  const navigateToFree = () => {
    navigate("/signup/");
  };

  const navigateToEvent = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.jotform.com/form/230056389150049", "_blank");
    }
  };

  const navigateToContact = () => {
    navigate("/contact-us/");
  };

  return (
    <section className="max-w-6xl mx-auto flex md:flex-row flex-col gap-6 justify-between mb-16">
      <PricingCard
        title="Free"
        onClick={() => navigateToFree()}
        description="Everything you need to create, post, and manage your social auctions"
        cost={0}
        costPer="auction"
        buttonTitle="Try for Free"
        offerings={
          <>
            <p>
              <strong>Fees</strong>
            </p>
            <p className="mt-2">0% service fee</p>
            <p className="mt-4">
              <strong>What&apos;s included</strong>
            </p>
            <p className="mt-2">3 ways to auction</p>
            <p className="mt-2">Auto-reply to bids</p>
            <p className="mt-2">CAD, USD, EUR and GBP support</p>
            <p className="mt-2">Free Bidddy Live listings</p>
            <p className="mt-2">Human support</p>
          </>
        }
      />
      <PricingCard
        title="Events"
        onClick={() => navigateToEvent()}
        description="Reach more donors with a custom event page and multiplatform bidding"
        cost={500}
        costPer="event"
        buttonTitle="Create an event"
        offerings={
          <>
            <p>
              <strong>Fees</strong>
            </p>
            <p className="mt-2">0% service fee</p>
            <p className="mt-4">
              <strong>What&apos;s included</strong>
            </p>
            <p className="mt-2">Everything in Free</p>
            <p className="mt-2">One-click bidding</p>
            <p className="mt-2">Custom Event Page</p>
            <p className="mt-2">Multiplatform bidding</p>
            <p className="mt-2">In-person bidding</p>
            <p className="mt-2">Post-event growth report</p>
          </>
        }
      />
      <PricingCard
        title="Auction Expert"
        onClick={() => navigateToContact()}
        description="Our experts will help your team get the most out of your auction"
        cost={150}
        costPer="hour"
        buttonTitle="Connect with us"
        offerings={
          <>
            <p>
              <strong>What&apos;s included</strong>
            </p>
            <p className="mt-2">Free Consultation</p>
            <p className="mt-2">Social auction strategy</p>
            <p className="mt-2">Marketing support</p>
            <p className="mt-2">Design support</p>
            <p className="mt-2">Team training</p>
            <p className="mt-2">1-1 dedicated support</p>
          </>
        }
      />
    </section>
  );
};

export default PricingPlans;
