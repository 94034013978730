/* eslint-disable consistent-return */
import React, { useEffect, useState, useContext } from "react";
import { PhoenixSocketContext } from "../websockets/PhoenixSocketProvider";

const useChannel = ({ auctionId }) => {
  console.log("SETTING UP CHANNEL: ", auctionId)
  const [channel, setChannel] = useState(null);
  const [data, setData] = useState(null);
  const { socket } = useContext(PhoenixSocketContext);

  useEffect(() => {
    let phoenixChannel;
    console.log("Connecting");
    if (socket && auctionId) {
      console.log("socket and auction exists");
      const channelId = `auction:${auctionId}`;
      phoenixChannel = socket.channel(channelId);
      phoenixChannel
        .join()
        .receive("ok", () => {
          console.log("JOINED CHANNEL");
          setChannel(phoenixChannel);
        })
        .receive("error", (resp) => {
          console.log("Unable to join", resp);
        });
    }

    return () => {
      if (phoenixChannel) {
        phoenixChannel.leave();
      }
    };
  }, [auctionId, socket]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    if (channel) {
      channel.on("bid", (payload) => {
        console.log("bid", payload);
        setData(payload);
      });
    }
    return () => {
      channel.off("bid");
    };
  }, [channel]);

  return [data];
};

export default useChannel;
