/* eslint-disable consistent-return */
import { toast, ToastOptions } from "react-toastify";
import { CurrencyTypes } from "../enums/CurrencyTypes";
import { AuctionStateTypes } from "../enums/AuctionStateTypes";
import { ErrorTypes } from "../enums/ErrorTypes";
import { ScheduledAuction } from "../store/auctions/actions";
import { Auction } from "../types/Auction";
import styles from "./Toast.module.scss";

export const timeout = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const sendToast = (text: any, args?: ToastOptions) => {
  const defaultArgs: ToastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    className: styles.newToastContainer,
  };
  toast(text, { ...defaultArgs, ...args });
};

const errorMessaging = (errorType: ErrorTypes) => {
  switch (errorType) {
    case ErrorTypes.InvalidCredentials:
      return "You have entered invalid credentials, please try again.";
    default:
      return "There was an error with your request, please try again.";
  }
};
export const withToastForError =
  (payloadCreator: (args: any) => Promise<any>) => async (args: any) => {
    try {
      return await payloadCreator(args);
    } catch (err: any) {
      let message = "";
      if (err.response.data) {
        if (err.response.data.error) {
          message = errorMessaging(err.response.data.error);
        }
        if (err.response.data.errors) {
          Object.keys(err.response.data.errors).forEach((key) => {
            message += `${key}: ${err.response.data.errors[key]}\n`;
          });
        }
      }
      sendToast(message, { type: "error" });
      throw err; // throw error so createAsyncThunk will dispatch '/rejected'-action
    }
  };

export const getAuctionState = (auction?: Auction | ScheduledAuction) => {
  if (!auction) {
    return;
  }
  return AuctionStateTypes[auction.progress];
};

export const isMobile = {
  Android() {
    return (
      typeof navigator !== "undefined" && navigator.userAgent.match(/Android/i)
    );
  },
  BlackBerry() {
    return (
      typeof navigator !== "undefined" &&
      navigator.userAgent.match(/BlackBerry/i)
    );
  },
  iOS() {
    return (
      typeof navigator !== "undefined" &&
      navigator.userAgent.match(/iPhone|iPad|iPod/i)
    );
  },
  Opera() {
    return (
      typeof navigator !== "undefined" &&
      navigator.userAgent.match(/Opera Mini/i)
    );
  },
  Windows() {
    return typeof navigator !== "undefined"
      ? navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i)
      : false;
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const encode = (data: any) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");

export const showAllErrors = (errors) => {
  if (typeof errors !== "object") {
    return;
  }
  Object.keys(errors).forEach((key) => {
    errors[key].forEach((msg) => {
      sendToast(`${msg}`, { type: "error" });
    });
  });
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    default:
      return "$";
  }
};

export const getCurrencyLocaleValue = (
  currency: CurrencyTypes | string,
  amount?: number
) =>
  new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency || "CAD",
  })
    .format(amount || 0)
    .replace("CA", ""); // We add this here because Intl CAD currency returns CA$x,xxx
