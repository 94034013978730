/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import { CarouselCard, CarouselImage } from 'react-rainbow-components';
import { intervalToDuration, zeroPadTimeValue } from "../../utils/date_helpers";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import {
  ExhibitResponse,
  // getPublicAuction,
  PublicAuctionResponse,
  getPublicAuction,
} from "../../store/events/actions";
import { selectEvent, selectPublicAuction } from "../../store/events/selectors";
import DefaultButton from "../Buttons/DefaultButton";
import styles from "./PublicMainAuction.module.scss";
import { AuctionStateTypes } from "../../enums/AuctionStateTypes";
import { getCurrencyLocaleValue, sendToast } from "../../utils/helpers";
import { useAppDispatch } from "../../store";
import DefaultInput from "../Inputs/DefaultInput";
import { LoginProps, signIn } from "../../store/auth/actions";
import { getAuthSuccess } from "../../store/auth/selectors";
import useConfirmBidHook from "../Hooks/useConfirmBidHook";
import useAuthHook from "../Hooks/useAuthHook";
import { selectConfirmBidError, selectSuccessfullySubmittedBid } from "../../store/bidding/selectors";
import { clearState } from "../../store/bidding/reducer";
import { clearSuccessState } from "../../store/auth/reducer";
import BidddyLogoSmall from "../Images/BidddyLogoSmall";
import CloseButton from "../PageComponents/Dashboard/Auctions/AuctionCreation/internal/CloseButton";
import { onPaste, preventNonNumeric } from "../Inputs/internal/helpers";
import { checkMinBidRequirements } from "./utils/helpers";
import GoogleButton from "../Inputs/GoogleButton";
import { baseURL, isAuthenticated } from "../../utils/axios";
import { BidderBidProps, submitBid } from "../../store/bidding/actions";
import { PhoenixSocketProvider } from "./websockets/PhoenixSocketProvider";
import useChannel from "./hooks/useChannel";

const InstagramIcon = () => (
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.3333 4.58325H18.6666C10.9346 4.58325 4.66663 10.7393 4.66663 18.3333V36.6666C4.66663 44.2605 10.9346 50.4166 18.6666 50.4166H37.3333C45.0653 50.4166 51.3333 44.2605 51.3333 36.6666V18.3333C51.3333 10.7393 45.0653 4.58325 37.3333 4.58325ZM9.33327 18.3333C9.33327 13.2707 13.5119 9.1666 18.6666 9.1666H37.3333C42.4879 9.1666 46.6666 13.2707 46.6666 18.3333V36.6666C46.6666 41.7292 42.4879 45.8333 37.3333 45.8333H18.6666C13.5119 45.8333 9.33327 41.7292 9.33327 36.6666V18.3333ZM27.9999 38.9583C21.5566 38.9583 16.3333 33.8282 16.3333 27.4999C16.3333 21.1717 21.5566 16.0416 27.9999 16.0416C34.4433 16.0416 39.6666 21.1717 39.6666 27.4999C39.6666 33.8282 34.4433 38.9583 27.9999 38.9583ZM28 34.3749C31.866 34.3749 35 31.2969 35 27.4999C35 23.703 31.866 20.6249 28 20.6249C24.134 20.6249 21 23.703 21 27.4999C21 31.2969 24.134 34.3749 28 34.3749ZM41.9999 16.0416C41.9999 17.3072 40.9553 18.3332 39.6666 18.3332C38.3779 18.3332 37.3333 17.3072 37.3333 16.0416C37.3333 14.7759 38.3779 13.7499 39.6666 13.7499C40.9553 13.7499 41.9999 14.7759 41.9999 16.0416Z"
      fill="#DBD8DD"
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C5.92487 22 1 17.0751 1 11C1 4.92487 5.92487 0 12 0C18.0751 0 23 4.92487 23 11C23 17.0751 18.0751 22 12 22ZM12 20C16.9706 20 21 15.9706 21 11C21 6.02944 16.9706 2 12 2C7.02944 2 3 6.02944 3 11C3 15.9706 7.02944 20 12 20ZM15.2929 7.29289L10 12.5858L7.70711 10.2929L6.29289 11.7071L10 15.4142L16.7071 8.70711L15.2929 7.29289Z"
      fill="#fff"
    />
  </svg>
);

const PublicMainAuction = () => {
  const dispatch = useAppDispatch();
  const publicAuction = useSelector((state) =>
    selectPublicAuction(state)
  ) as PublicAuctionResponse | null;
  const successfullySubmittedBid = useSelector((state) =>
    selectSuccessfullySubmittedBid(state)
  );
  // FIX THIS.
  const bidError = useSelector((state) => selectConfirmBidError(state));
  const [bidData] = useChannel({
    auctionId:
      publicAuction && publicAuction.data
        ? publicAuction.data.token
        : null,
  });

  useEffect(() => {
    if (bidError) {
      sendToast("There was an error confirming your bid. Please try again", { type: 'error' })
    }
  }, [bidError])

  // Handle incoming bids
  useEffect(() => {
    if (bidData && publicAuction && publicAuction.data && publicAuction.data.token) {
      dispatch(getPublicAuction(publicAuction.data.token));
    }
  }, [bidData]);

  const success = useSelector((state) => getAuthSuccess(state));
  const event = useSelector((state) =>
    selectEvent(state)
  ) as ExhibitResponse | null;

  const [authenticated] = useAuthHook();
  useConfirmBidHook({ authenticated });

  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [mins, setMins] = useState("00");

  const [showBiddingModal, setShowBiddingModal] = useState(false);
  const [email, setEmail] = useState("");
  const [bidAmount, setBidAmount] = useState("");
  const [showCustomBidIncrement, setShowCustomBidIncrement] = useState(false);

  const [googleParams, setGoogleParams] = useState("");

  const isAuctionFinished = () => {
    const currentDate = new Date();
    const endingDate = new Date(publicAuction.data.auction.ends_at);
    const timeDiff = endingDate.getTime() - currentDate.getTime();

    if (timeDiff < 0) {
      return true;
    }
    return false;
  };

  const getCountdown = () => {
    const currentDate = new Date();
    const endingDate = new Date(publicAuction.data.auction.ends_at);
    const { hours, days, minutes } = intervalToDuration(
      currentDate,
      endingDate
    );
    setHours(zeroPadTimeValue(hours));
    setDays(zeroPadTimeValue(days));
    setMins(zeroPadTimeValue(minutes));
  };

  useEffect(() => {
    if (successfullySubmittedBid) {
      // Say the amount of the bids
      setBidAmount("");
      setShowCustomBidIncrement(false);
      sendToast(
        <>
          <p>
            <strong>Bid confirmed</strong>
          </p>
          <p>You now have the highest bid!</p>
        </>,
        {
          type: "success",
          icon: <SuccessIcon />,
          className: styles.successToastContainer,
        }
      );
      dispatch(clearState());
      // // REFETCH THE AUCTION
      if (publicAuction && publicAuction.data && publicAuction.data.token) {
        dispatch(getPublicAuction(publicAuction.data.token));
      }
      
    }
  }, [successfullySubmittedBid]);

  useEffect(() => {
    if (success) {
      // TODO: display on modal "CHECK YOUR INBOX"
      sendToast(
        <>
          <p>
            <strong>Successfully submitted email.</strong>
          </p>
          <p>Please check your email for further instructions.</p>
        </>,
        {
          type: "success",
          icon: <SuccessIcon />,
          className: styles.successToastContainer,
        }
      );
      dispatch(clearSuccessState());
      setShowBiddingModal(false);
    }
  }, [success]);

  useEffect(() => {
    let TimeInterval;
    if (
      publicAuction &&
      publicAuction.data &&
      publicAuction.data.auction.progress === AuctionStateTypes.publish
    ) {
      if (!isAuctionFinished()) {
        getCountdown();
      }
      TimeInterval = setInterval(() => {
        if (!isAuctionFinished()) {
          getCountdown();
        } else {
          setDays("00");
          setMins("00");
          setHours("00");
        }
      }, 30000);
    }
    return () => {
      if (TimeInterval) {
        clearInterval(TimeInterval);
      }
    };
  }, [publicAuction]);

  useEffect(() => {
    const params = {
      redirect: `${window.location.href.split("?")[0]}`,
      type: "bidder",
      bid: bidAmount,
    };
    const encodedURI = encodeURI(JSON.stringify(params));

    setGoogleParams(`${baseURL}/auth/google?state=${encodedURI}`);
  }, [bidAmount]);

  const handleBid = (bid) => {
    // TODO: check if user is authenticated
    if (Number.isNaN(bid)) {
      sendToast("Please enter a valid bid amount", { type: "error" });
      return;
    }
    if (!checkMinBidRequirements(bid, publicAuction)) {
      sendToast(
        `Your bid you've entered is below the minimum bid amount of ${getBidIncrement(
          0
        )}`,
        { type: "error" }
      );
      return;
    }
    if (!authenticated) {
      // handle link
      setBidAmount(bid);
      setShowBiddingModal(true);
      setShowCustomBidIncrement(false);
    } else {
      // Check if The cookie state has been reset
      if (!isAuthenticated()) {
        setBidAmount(bid);
        setShowBiddingModal(true);
        setShowCustomBidIncrement(false);
        return;
      }
      const props = {
        bid: { amount: Number(bid) },
        token: publicAuction.data.token,
      } as BidderBidProps;
      dispatch(submitBid(props));
    }
  };

  const getBannerImage = () => {
    switch (publicAuction.data.auction.progress) {
      case "publish":
        return <div className={cn([styles.banner, styles.live])}>LIVE</div>;
      case "finish":
        return <div className={cn([styles.banner, styles.closed])}>CLOSED</div>;
      default:
        return (
          <div className={cn([styles.banner, styles.closed])}>COMING SOON</div>
        );
    }
  };

  const navigateToInstagramPost = () => {
    window.location.href = publicAuction.data.permalink;
  };

  const getButtonText = () => {
    if (publicAuction.data.auction.progress !== "publish") {
      if (publicAuction.data.auction.progress !== "finish") {
        return "COMING SOON";
      }
      return "CLOSED";
    }
    return "CUSTOM BID";
  };

  // validate email regex check
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleConfirmEmail = () => {
    // validate email
    if (!validateEmail(email)) {
      return sendToast("Please enter a valid email address", { type: "error" });
    }
    // If providing the bid we need the auction id.
    // We need to associate the bid to the auction
    // I need to handle to handle minimum bid increment

    const userData = {
      bidder: {
        email,
        redirect: window.location.href.split("?")[0],
      },
      bid: bidAmount,
    } as LoginProps;

    return dispatch(signIn(userData));
  };

  const getBidIncrement = (index: number) => {
    const bidIncrement =
      (publicAuction.data.auction.current_bid_price ||
        publicAuction.data.auction.price) +
      publicAuction.data.auction.increment * (index + 1);
    return bidIncrement;
  };

  const handleNumericalInput = (value) => {
    // TODO: need to fix this
    // const amount = parseFloat(value.replace(/[^.\d.\-eE+]/g, ""));
    if (Number.isNaN(value)) {
      return setBidAmount("");
    }
    setBidAmount(value);
  };

  const handleBidInput = (e) => {
    if (e.key === "Enter") {
      handleBid(bidAmount);
      return;
    }
    preventNonNumeric(e, false);
  };

  const AUCTION_HAS_IMAGE =
    publicAuction && publicAuction.data && publicAuction.data.auction.image_url;
  const images = publicAuction && publicAuction.data && publicAuction.data.auction.images;
  return (
    <PhoenixSocketProvider>
      <div className={styles.publicMainContainer}>
        <div className={styles.publicMainImageContainer}>
          {AUCTION_HAS_IMAGE && (!images || images.length === 0) && (
            <>
              {getBannerImage()}
              <Imgix
                src={`${publicAuction.data.auction.image_url}`}
                height={570}
                width={570}
                htmlAttributes={{
                  onError: ({ currentTarget }) => {
                    currentTarget.onError = null;
                    currentTarget.src = null;
                  },
                  alt: "Public Auction Image",
                  src: `${publicAuction.data.auction.image_url}`,
                }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
                className="lazyload blur-up"
              />
            </>
          )}
          {images && images.length > 0 && (
            <>
              {getBannerImage()}
              <CarouselCard disableAutoScroll>
                {images.map((image, index) => (
                  <CarouselImage
                    key={`auction-index-${index}`}
                    assistiveText={`Auction Image ${index}`}
                    src={image}
                  />
                ))}
            </CarouselCard>
            </>
          )}
        </div>
        <div className={styles.publicMainContentContainer}>
          <h4 className={styles.user}>
            <a
              target="_blank"
              rel="noreferrer nofollow noopener"
              href={`http://instagram.com/${publicAuction.data.username}`}
            >
              @
              {publicAuction &&
                publicAuction.data &&
                publicAuction.data.username}
            </a>
          </h4>
          <h1 className={styles.auctionName}>
            {publicAuction && publicAuction.data && publicAuction.data.name}
          </h1>
          <p className={styles.auctionDescription}>
            {publicAuction &&
              publicAuction.data &&
              publicAuction.data.description}
          </p>
          <div className={styles.section}>
            <label>Current Bid</label>
            <h3>
              {publicAuction &&
                publicAuction.data &&
                `${getCurrencyLocaleValue(
                  publicAuction.data.auction.currency,
                  publicAuction.data.auction.current_bid_price
                )}`}
            </h3>
          </div>
          <div className={styles.section}>
            <label>Ends in:</label>
            <h4>
              {days} Day{days !== "01" && "s"} {hours} Hr{hours !== "01" && "s"}{" "}
              {mins} Min{mins !== "01" && "s"}
            </h4>
          </div>
          <div className={styles.section}>
            <label>Number of Bids</label>
            <h4>
              {publicAuction &&
                publicAuction.data &&
                publicAuction.data.auction.bid_count}{" "}
              Bids
            </h4>
          </div>
          <div className={styles.infoAlertContainer}>
            <InstagramIcon />
            <div className={styles.biddingCopyContainer}>
              <p>All bidding is done live on Instagram and Bidddy</p>
              {event && event.data && (
                <p className={styles.usersInvolved}>
                  {/* publicAuction && publicAuction.data && publicAuction.data.username ? <><a target="_blank" rel="noreferrer nofollow noopener" href={`http://instagram.com/${publicAuction.data.username}`}>@{publicAuction.data.username}</a> and</> : '' */}{" "}
                  <a
                    target="_blank"
                    rel="noreferrer nofollow noopener"
                    href={`http://instagram.com/${event.data.username}`}
                  >
                    @{event && event.data && event.data.username}
                  </a>
                </p>
              )}
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => navigateToInstagramPost()}
                className={styles.learnMore}
                onClick={() => navigateToInstagramPost()}
              >
                View Instagram Post
              </div>
            </div>
          </div>
          <div className={styles.actionsContainer}>
            <div className="grid grid-cols-4 w-full max-w-none gap-4 justify-between overflow-x-auto">
              {publicAuction.data.auction.progress === "publish" && (
                <>
                  <DefaultButton
                    outlined
                    extraClasses={styles.minWidthButton}
                    title={`${getCurrencyLocaleValue(
                      publicAuction.data.auction.currency,
                      getBidIncrement(0)
                    )}`}
                    onClick={() => handleBid(getBidIncrement(0))}
                  />
                  <DefaultButton
                    outlined
                    extraClasses={styles.minWidthButton}
                    title={`${getCurrencyLocaleValue(
                      publicAuction.data.auction.currency,
                      getBidIncrement(1)
                    )}`}
                    onClick={() => handleBid(getBidIncrement(1))}
                  />
                  <DefaultButton
                    outlined
                    extraClasses={styles.minWidthButton}
                    title={`${getCurrencyLocaleValue(
                      publicAuction.data.auction.currency,
                      getBidIncrement(2)
                    )}`}
                    onClick={() => handleBid(getBidIncrement(2))}
                  />
                </>
              )}
              {!showCustomBidIncrement && (
                <DefaultButton
                  extraClasses={styles.minWidthButton}
                  disabled={publicAuction.data.auction.progress !== "publish"}
                  title={getButtonText()}
                  onClick={() => setShowCustomBidIncrement(true)}
                />
              )}
              {showCustomBidIncrement && (
                <div className="flex flex-row">
                  <DefaultInput
                    className={styles.shortPad}
                    value={bidAmount}
                    autoFocus
                    placeholder="Enter Bid"
                    onPaste={(e: any) => onPaste(e, true, false)}
                    onKeyDown={(e: any) => handleBidInput(e)}
                    onChange={(e) => handleNumericalInput(e.target.value)}
                  />
                  <button
                    type="button"
                    aria-label="Confirm Bid"
                    disabled={Number.isNaN(bidAmount)}
                    className={styles.pinkCheckmarkButton}
                    onClick={() => handleBid(bidAmount)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showBiddingModal && (
        <div className="fixed bg-opacity-70 bg-black inset-0 z-10">
          <div className="w-full h-full lg:w-1/2 lg:max-w-md bg-white lg:h-auto lg:rounded-lg shadow-sm mx-auto lg:mt-12 flex flex-col justify-center items-center p-10 relative">
            {/* Bidddy Logo here */}
            <CloseButton
              onClick={() => setShowBiddingModal(false)}
              extraClasses={styles.topCloseButton}
            />
            <BidddyLogoSmall />
            <h3 className="text-base text-bidddyPink font-inter font-bold mt-8">
              Almost there!
            </h3>
            <h2 className="text-2xl text-bidddyAltGray mb-2">
              Confirm your email
            </h2>
            <p className="mb-4">
              To complete your bid of{" "}
              {getCurrencyLocaleValue(
                publicAuction.data.auction.currency,
                Number(bidAmount)
              )}
              , we need to know who to contact if you win! Enter your email and
              we&apos;ll send you a magic confirmation link. 🪄
            </p>
            {/* TODO: add on enter functionality here */}
            <DefaultInput
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <DefaultButton
              extraClasses={styles.confirmButton}
              title="Confirm"
              onClick={() => handleConfirmEmail()}
              disabled={!email}
            />
            <p className="mt-4">OR</p>
            <GoogleButton className={styles.googleButton} href={googleParams}>
              Confirm with Google
            </GoogleButton>
          </div>
        </div>
      )}
    </PhoenixSocketProvider>
  );
};

export default PublicMainAuction;
